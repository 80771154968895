import weightMateIos from '../../../../gallery/weight-mate-ios.png';
import movieWorld from '../../../../gallery/movie-world-img.png';
import React, { useEffect } from 'react';
import ScreenImage from './ScreenImage';
import ProjectContents from './ProjectContents';

const teamProject = [
  {
    name: 'movie-world',
    kind: 'web',
    img: movieWorld,
    unit: '사용 기술: TypeScript, React, Next.js, Tailwind.css',
    path: '',
    gitHub: 'https://github.com/kthdd1234/movie-world',
    isStore: false,
  },
  {
    name: '체중 메이트(Android, IOS)',
    kind: 'app',
    img: weightMateIos,
    unit: '사용 기술: Flutter, Provider, Hive, Flutter_local_notifications',
    path: '',
    gitHub: 'https://github.com/kthdd1234/weight-mate',
    isStore: true,
  },
];

const TeamProject = ({ history, observeFunc }) => {
  useEffect(() => {
    const observer = window.document.querySelector('.projects-team');
    observeFunc(observer).observe(observer);
  });

  return (
    <div className='projects-team'>
      {teamProject.map((project, i) => (
        <div className='projects-project' key={i}>
          <ScreenImage kind={project.kind} img={project.img} />
          <ProjectContents
            name={project.name}
            unit={project.unit}
            path={project.path}
            history={history}
            gitHub={project?.gitHub}
            isStore={project.isStore}
          />
        </div>
      ))}
    </div>
  );
};

export default TeamProject;
