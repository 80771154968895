import profileImage from '../../../gallery/profile_image.png';
import companyLogo from '../../../gallery/colorverse-logo.jpeg';

const MyPhoto = () => {
  return (
    <div className='about-photo'>
      <img src={profileImage} className='about-photo-img' alt='img' />
      {/* <img src={companyLogo} className='about-photo-logo' alt='img' /> */}
    </div>
  );
};

export default MyPhoto;
