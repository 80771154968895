const DecorBottom = () => {
  return (
    <img
      className='decor-bottom'
      src='https://assets.website-files.com/5e56267ff063d5bd8357b5ee/5e562680e45560e05b9e13b5_decor-v1-haze.svg'
    />
  );
};

export default DecorBottom;
