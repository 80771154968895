import SFCINEMA from '../../../../gallery/SFCINEMA.png';
import SFCINEMA_web_main from '../../../../gallery/SFCINEMA_web_main.png';
import SFCINEMA_web_details from '../../../../gallery/SFCINEMA_web_details.png';
import ProjectNavBar from '../components/ProjectNavBar';
import ProjectIntro from '../components/ProjectIntro';
import ProjectGIF from '../components/ProjectGIF';

const Desc = () => {
  return (
    <div>
      사용자에게 다양한 <span className='project-intro-desc-sf'>SF</span> 영화를
      소개해주는 <span className='project-intro-desc-tomato'>웹사이트</span>
      입니다.
    </div>
  );
};

const project = {
  contents: {
    type: 'web',
    position: 'Full-Stack',
    term: '2020년 7월 7일 ~ ',
    stack: 'React • Node.js • AWS',
    desc: <Desc />,
    backgroundImg: `https://image.tmdb.org/t/p/original/pZvZjxPFfWWIwtPQodsNygQ6u5Z.jpg`,
  },
  logo: {
    img: SFCINEMA,
    width: 'large',
    radius: 'circle',
  },
  link: {
    before: '/projects/meet-you',
    next: '/projects/sfcinema-app',
    more: 'https://github.com/kthdd1234/SF-CINEMA',
  },
  GIF1: {
    title: '메인 화면',
    gif: SFCINEMA_web_main,
    backgroundColor: '#202a45',
  },
  GIF2: {
    title: '영화 정보 및 예고편',
    gif: SFCINEMA_web_details,
    backgroundColor: 'rgb(20, 21, 23)',
  },
};

const SFcinemaWeb = ({ history }) => {
  return (
    <div>
      <ProjectNavBar more={project.link.more} history={history} />
      <ProjectIntro
        contents={project.contents}
        logo={project.logo}
        link={project.link}
        history={history}
      />
      <ProjectGIF type={project.contents.type} GIF={project.GIF1} />
      <ProjectGIF type={project.contents.type} GIF={project.GIF2} />
    </div>
  );
};

export default SFcinemaWeb;
