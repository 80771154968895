import { Button } from 'antd';
import appstore from '../../../../gallery/app-store-button.png';
import playstore from '../../../../gallery/google-play-badge-remove-preview.png';

const ProjectContents = ({ name, unit, path, history, gitHub, isStore }) => {
  const onClickAndroid = () => {
    window.open(
      'https://play.google.com/store/apps/details?id=com.kthdd.weightMate'
    );
  };

  const onClickIOS = () => {
    window.open(
      'https://apps.apple.com/kr/app/%EC%B2%B4%EC%A4%91-%EB%A9%94%EC%9D%B4%ED%8A%B8-%EC%B2%B4%EC%A4%91-%EA%B8%B0%EB%A1%9D%EA%B3%BC-%EB%8B%A4%EC%9D%B4%EC%96%B4%ED%8A%B8-%EA%B3%84%ED%9A%8D-%EC%8B%A4%EC%B2%9C/id6451026823'
    );
  };

  const onClickSFCinema = () => {
    gitHub ? window.open(gitHub) : history.push(`/projects/${path}`);
  };

  const ButtonComponent = ({ buttonName, color, onClick }) => {
    return (
      <Button
        style={{
          marginTop: '5px',
          color: color,
          fontWeight: 'bold',
          borderRadius: 5,
        }}
        type='primary'
        size='large'
        ghost={true}
        onClick={onClick}
      >
        {buttonName}
      </Button>
    );
  };

  return (
    <div className='projects-project-contents'>
      <div className='projects-project-contents-name'>{name}</div>
      <div className='projects-project-contents-pos'>{unit}</div>
      <div
        className='projects-project-contents-btns'
        style={{ display: 'flex' }}
      >
        <ButtonComponent
          className='projects-project-contents-btns-pro'
          buttonName='프로젝트 보기 →'
          color='#1890ff'
          onClick={onClickSFCinema}
        />
        {isStore && (
          <img
            className='android-app'
            style={{
              width: '135px',
              height: '51px',
              marginLeft: '5px',
              cursor: 'pointer',
            }}
            src={playstore}
            alt='android'
            onClick={onClickAndroid}
          />
        )}
        {isStore && (
          <img
            style={{
              width: '135px',
              height: '40px',
              marginLeft: '5px',
              marginTop: '5px',
              cursor: 'pointer',
            }}
            src={appstore}
            alt='ios'
            onClick={onClickIOS}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectContents;
